import { useEffect, useState, useRef } from "react";
import { GoLaw } from 'react-icons/go';
import { FaPhoneAlt } from 'react-icons/fa';

// -------------------------------[ HEADER SECTION ]------------------------------- //

export default function Header() {
    const [isNavbarSticky, setIsNavbarSticky] = useState(false);
    const navbarAreaEl = useRef(null);

    function fixNavBar() {
        if (navbarAreaEl.current) {
            setIsNavbarSticky(window.pageYOffset > navbarAreaEl.current.offsetTop)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', fixNavBar);
        return () => {
            window.removeEventListener('scroll', fixNavBar);
        }
    }, []);

    return (
        <header className="header">
            <div ref={navbarAreaEl}>
                <div className="row align-items-center">
                    <div style={{ height: '85px' }}>
                        <nav id="navbar" className={`navbar navbar-expand-lg fixed-top ${isNavbarSticky ? 'nav-back-diff shadow-sm' : 'nav-same-back'}`} >
                            <div className="container align-items-center">
                                <a className="navbar-brand" href="!#">
                                    <div className="d-flex align-items-center gap-1" >
                                        <h1><GoLaw /></h1>
                                        <h2 style={{ margin: '0', padding: '0' }}>Abogada Baviera</h2>
                                    </div>
                                </a>
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation" >
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse justify-content-end" id="navbarContent">
                                    <ul className="navbar-nav nav-pills text-start ps-4 pe-4">
                                        <li className="nav-item">
                                            <a className="nav-link" href="#scrollspyHeading1">Inicio</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#scrollspyHeading2">Quién soy</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#scrollspyHeading3">Contacto</a>
                                        </li>
                                    </ul>
                                    <div className="vr d-none d-lg-block me-3 ms-3" ></div>
                                    <hr className=" d-block d-lg-none " ></hr>
                                    <a href="tel:+56999119557" className="call d-flex align-items-center gap-3 justify-content-center mb-2">
                                        <h2><FaPhoneAlt /></h2>
                                        <div style={{ textAlign: 'left' }}>
                                            <h6 className="fw-light mb-1 d-none d-lg-block" style={{ margin: '0', padding: '0' }}>llámame al:</h6>
                                            <h4 style={{ margin: '0', padding: '0' }}>+56999119557</h4>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
}
