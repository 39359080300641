import { FaFacebook } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FaPhoneAlt } from 'react-icons/fa';
import { MdMail } from 'react-icons/md';

// -------------------------------[ FOOTER SECTION ]------------------------------- //

export default function Footer() {
    return (
        <footer className="footer">
            <div className="container text-center">
                <hr class="mb-5 text-muted" />
                <div className="row">
                    <div className="col-12 col-sm-3">
                        <div className="footer-slot">
                            <h3>Sobre mí</h3>
                            <p className='text-justify'>
                                Licenciada en Ciencias Jurídicas y Sociales
                                de la Universidad de Concepción e investida
                                como Abogada por la Excelentísima Corte Suprema,
                                entrego la mejor atención y solución
                                a mis clientes.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-3">
                        <div className="footer-slot" >
                            <h3>Sígueme</h3>
                            <div className="social-links" >
                                <a href="https://www.facebook.com/profile.php?id=100092670420080" target="_blank" rel="noopener noreferrer" className='face'><FaFacebook /></a>
                                <a href="https://www.instagram.com/abogadabaviera/" target="_blank" rel="noopener noreferrer" className='insta'><FaInstagram /></a>
                                <a href="https://www.linkedin.com/in/baviera-stheffany-albina" target="_blank" rel="noopener noreferrer" className='linke'><FaLinkedin /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-3">
                        <div className="footer-slot">
                            <h3>Datos</h3>
                            <div className="d-flex  align-items-start mb-3 text-start" >
                                <FaMapMarkerAlt size={20} />
                                <p style={{ margin: '0', padding: '0', marginLeft: '5px' }}>Ubicada en Talca con atención en la Región del Maule y Zona Centro Sur del País</p>
                            </div>
                            <div className="d-flex  align-items-center mb-3" >
                                <FaPhoneAlt size={20} />
                                <p style={{ margin: '0', padding: '0', marginLeft: '5px' }}>+56999119557</p>
                            </div>
                            <div className="d-flex  align-items-center mb-3" >
                                <MdMail size={20} />
                                <p style={{ margin: '0', padding: '0', marginLeft: '5px' }}>abogadabaviera@gmail.com</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-3">
                        <div className="footer-slot">
                            <h3>Guarda mi contacto</h3>
                            <img src="./qr-code.svg" className="figure-img img-fluid" alt="owner qr code" width={140}></img>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mx-auto mt-sm-4">
                    <div className="footer-slot">
                        {/* <h3>Realizar pago</h3> */}
                        <a href="https://www.webpay.cl/form-pay/218425" target="_blank" rel="noopener noreferrer">
                            <img src="./LOGO WEBPAY RGB-01.png" className="figure-img img-fluid" alt="online payment with webpay.cl from transbank" width={140} />
                        </a>
                        <div className="d-flex justify-content-around mt-2">
                            <img src="./visa.svg" className="figure-img img-fluid" alt="online pay with webpay.cl transbank" width={30} />
                            <img src="./master.svg" className="figure-img img-fluid" alt="online pay with webpay.cl transbank" width={30} />
                            <img src="./american.svg" className="figure-img img-fluid" alt="online pay with webpay.cl transbank" width={22} />
                            <img src="./dinners.svg" className="figure-img img-fluid" alt="online pay with webpay.cl transbank" width={28} />
                            <img src="./redcompra.svg" className="figure-img img-fluid" alt="online pay with webpay.cl transbank" width={70} />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
